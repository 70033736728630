.dropdown{
    transition: all 2s ease;
}

.location{
    display: inline-block;
}

.description{
    white-space: pre-line;
}